var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modalEditRole",
    attrs: {
      "id": "modal-handle-employees-role",
      "header-bg-variant": "light-info",
      "centered": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "font-weight-bolder m-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('employee.role')) + " ")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 text-center"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
          },
          on: {
            "click": _vm.handleCancelEdit
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.back')) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', [_c('b-row', {
    staticClass: "mx-0 d-flex-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-form-group', {
    staticClass: "flex-grow-1",
    attrs: {
      "label": _vm.$t('employee.attachRole'),
      "label-for": "role-select"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "role-select",
      "options": _vm.createdByOptions,
      "label": "name",
      "searchable": "",
      "clearable": "",
      "multiple": "",
      "filterable": false,
      "loading": _vm.isLoading,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.name;
      }
    },
    on: {
      "open": _vm.handleOpenCreatedBy,
      "search": _vm.handleSearchCreatedBy
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.name) + " ")])])];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.name) + " ")])])];
      }
    }]),
    model: {
      value: _vm.roleSelected,
      callback: function callback($$v) {
        _vm.roleSelected = $$v;
      },
      expression: "roleSelected"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "text-center mt-75",
    attrs: {
      "cols": "3"
    }
  }, [_c('b-button', {
    staticClass: "ml-75",
    attrs: {
      "variant": "info",
      "disabled": _vm.roleSelected.length === 0
    },
    on: {
      "click": function click() {
        return _vm.addRole(_vm.employeeData.accountId);
      }
    }
  }, [_vm.isLoading ? _c('b-spinner', {
    staticClass: "align-middle"
  }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('employee.attach')) + " ")])], 1)], 1)], 1), _c('b-row', {
    staticClass: "mx-0 mt-1"
  }, [_c('b-table', {
    attrs: {
      "thead-class": 'text-center',
      "tbody-class": 'text-center',
      "fields": ['Role', 'Description', 'Action'],
      "items": _vm.roleOptions,
      "busy": _vm.isBusy,
      "responsive": "",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult')
    },
    scopedSlots: _vm._u([_vm._l(['Role', 'Description', 'Action'], function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("employee.".concat(data.label.toLowerCase()))) + " ")])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center text-danger my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(Role)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap d-flex justify-content-start ml-1"
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")])];
      }
    }, {
      key: "cell(Description)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap d-flex justify-content-start ml-1"
        }, [_vm._v(" " + _vm._s(data.item.description) + " ")])];
      }
    }, {
      key: "cell(Action)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "variant": "flat-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.handleRemoveRole(_vm.employeeData.accountId, data.item.name);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "Trash2Icon"
          }
        })], 1)], 1)];
      }
    }], null, true)
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }